<template>
  <v-content>
    <v-container grid-list-lg fluid style="margin-bottom: 58px;">
      <v-layout wrap align-start justify-start fill-height>
        <v-flex xs12>
          <AppTitle title="Atributo" subtitle="Ingrese una opcion de atributos" />
        </v-flex>
        <v-flex xs12>
          <v-card>
            <v-card-text>
              <v-container grid-list-lg fluid>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md12>
                    <v-text-field v-model="item.name" label="Titulo" hide-details></v-text-field>
                  </v-flex>
                  <v-radio-group v-model="item.status" :mandatory="false">
                    <v-radio color="secondary" label="Inactivo" :value="false"></v-radio>
                    <v-radio color="secondary" label="Activo" :value="true"></v-radio>
                  </v-radio-group>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="accent" :loading="loading" :disabled="loading" flat @click="save">
                Listo
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import AppTitle from '../../useful/title.vue'

export default {
  name: 'EditAtributo',
  components: {
    AppTitle
  },
  data() {
    return {
      loading: false,
      categories: [],
      item: {
        date: null,
        description: null,
        id: 0,
        idcat: 0,
        name: null,
        photo: null,
        resume: null,
        seodetalle: null,
        seokey: null,
        status: true,
        tags: null,
        url: null
      },
      loadHtml: false
    }
  }
}
</script>
